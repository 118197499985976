import { CopyContent } from "@/utils/tools";
export default {
  props: {
    user_address: {
      type: Object
    }
  },
  methods: {
    async CopyContent(e) {
      CopyContent(e, this.user_address.receive_name + ' ' + this.user_address.receive_phone + '\n' + this.user_address.receive_address);
    }
  }
};