import MDRouter from "@/service/router";
import { mdaftersaleCancel } from "@/api/index";
import { CopyContent } from "@/utils/tools";
import { mdAlert, mdToast } from "../../utils/tools";
import { mdaftersaleLeftNum } from "@/api";
export default {
  props: {
    status_describe: {
      type: Object
    },
    aftersale_id: {
      type: String
    },
    cp_address: {
      type: Object
    },
    btn_list: {
      type: Object
    },
    pro_info: {
      type: Object
    },
    order_id: {
      type: String
    },
    aftersale_type: {
      type: String
    }
  },
  methods: {
    btnClick(item) {
      if (item.type == 'md_aftersale_cancel') {
        if (this.aftersale_type == 2) {
          // 平台申诉阶段-直接取消，不用请求剩余次数
          this.sendAfterSaleCancelRequest();
        } else {
          // 撤销申请-剩余次数
          this.handleAfterSaleCancelAction();
        }
      } else if (item.type == 'md_aftersale_appeal') {
        // 申诉
        MDRouter.goAppeal({
          aftersale_id: this.aftersale_id,
          order_id: this.order_id,
          pro_id: this.pro_info.id
        });
      } else if (item.type == 'md_aftersale_write_express_no') {
        // 填写物流单号
        const url = `/order/logistics/add?aftersale_id=${this.aftersale_id}&pro_id= ${this.pro_info.id}&name=${this.pro_info.name}&url=${this.pro_info.logo2}`;
        MDRouter.Link(url, 'wap');
      }
    },
    async CopyContent(e) {
      CopyContent(e, this.cp_address.receive_name + ' ' + this.cp_address.receive_phone + '\n' + this.cp_address.receive_address);
    },
    // 点击取消售后按钮
    async handleAfterSaleCancelAction() {
      const {
        status,
        data,
        message
      } = await mdaftersaleLeftNum({
        order_id: this.order_id
      });
      if (status == 0) {
        mdAlert({
          title: '提示',
          content: data.show_msg,
          actions: ['取消', '确定'],
          _callback: () => {
            //取消请求
            this.sendAfterSaleCancelRequest();
          }
        });
      } else {
        mdToast(message);
      }
    },
    sendAfterSaleCancelRequest() {
      mdaftersaleCancel({
        aftersale_id: this.aftersale_id
      }).then(res => {
        if (res.status == 0) {
          mdToast("操作成功");
          this.$emit('reload');
        } else {
          mdToast(res.message);
        }
      });
    }
  }
};