import { env, domPixelRatio } from "md-base-tools/env";
import { CopyContent, PreviewImages, getListOne } from "@/utils/tools";
import { Icon } from 'vant';
import MDRouter from "../../service/router";
import Error from "@@/common/Error.vue";
import CompStep from "@@/after_sale/step.vue";
import CompDescribe from "@@/after_sale/describe.vue";
import CompUserAddress from "@@/after_sale/user_address.vue";
import CompProInfo from "@@/after_sale/pro_info.vue";
import { mdaftersalDetail, showVideoList } from "@/api/index";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    Icon,
    Error,
    CompStep,
    CompDescribe,
    CompUserAddress,
    CompProInfo
  },
  data() {
    return {
      env,
      domPixelRatio,
      loaded: false,
      aftersale_id: '',
      // 售后id
      order_id: '',
      // 订单id
      business_code: '',
      // 业务类型1=众筹 4=电商
      // 项目详情
      pro_info: {},
      // 回报档详情
      reward_info: {},
      // 订单详情
      order_info: {},
      // 进度条
      refund_time_line: [],
      // 描述---按钮信息
      btn_list: [],
      // 物流详情
      logistics_info: {},
      // 售后详情
      aftersale_info: {
        status_describe: {}
      },
      showVideo: false,
      imgList: [],
      videoUrl: ''
    };
  },
  async mounted() {
    const {
      aftersale_id,
      order_id
    } = this.$route.query || {};
    this.aftersale_id = aftersale_id || '';
    this.order_id = order_id || '';
    await this.getInfo();
    this.loaded = true;
    if (this.env.isModianAndroid() || this.env.isModianIos()) {
      document.addEventListener("visibilitychange", () => this.getInfo());
    }
  },
  beforeUnmount() {
    if (this.env.isModianAndroid() || this.env.isModianIos()) {
      document.removeEventListener('visibilitychange', () => this.getInfo());
    }
  },
  methods: {
    async getInfo() {
      if (this.aftersale_id || this.order_id) {
        const {
          status,
          data,
          message
        } = await mdaftersalDetail({
          aftersale_id: this.aftersale_id,
          order_id: this.order_id
        });
        console.log(666, data);
        if (status == 0) {
          const {
            business_code,
            pro_info,
            reward_info,
            order_info,
            aftersale_info,
            btn_list,
            logistics_info
          } = data;
          this.business_code = business_code || '';
          this.pro_info = pro_info || {};
          this.reward_info = reward_info || {};
          this.order_info = order_info || {};
          this.aftersale_info = aftersale_info || {};
          this.refund_time_line = aftersale_info && aftersale_info.refund_time_line ? aftersale_info.refund_time_line : [];
          this.btn_list = btn_list || [];
          this.logistics_info = logistics_info || {};
        } else {
          mdToast(message || '未获取到售后信息');
        }
      }
    },
    back() {
      // eslint-disable-next-line
      graft.app({
        functionName: "closeController",
        functionParams: "",
        callBack: () => {},
        webFun: () => {
          window.history.go(-1);
        }
      });
    },
    // 刷新页面
    reload() {
      window.location.reload();
    },
    // 联系客服
    goChat() {
      // 申诉详情---联系智齿客服
      if (this.aftersale_info.aftersale_type == 2) {
        MDRouter.goZCChat({
          scene: 0
        });
      } else {
        let pro_type = '';
        if (this.business_code == 1) {
          // 众筹
          pro_type = '101';
          MDRouter.goChat({
            pro_type,
            cp_user_id: this.pro_info.cp_userid,
            pro_id: this.pro_info.id
          });
        } else if (this.business_code == 4) {// 电商
          // pro_type = '901'
          // MDRouter.goChat({
          //     pro_type,
          //     shop_id: this.pro_info.cp_userid,
          //     pro_id: this.pro_info.id
          // })
        }
      }
    },
    async CopyContent(e, text) {
      await this.$nextTick();
      CopyContent(e, encodeURIComponent(text));
    },
    // 发货物流|退货物流
    goLogisticsInfo(item) {
      MDRouter.goLogisticsInfo({
        express_no: item.express_no,
        express_no_mix: item.express_no_mix,
        express_name: item.express_company
      });
    },
    // 查看大图
    showBigImg(index) {
      if (this.imgList.length == 0) {
        this.aftersale_info.apply_attachment.forEach(item => {
          if (item.file_type !== 'video') {
            this.imgList.push(item.url);
          } else {
            this.imgList.push(item.video_cover);
          }
        });
      }
      console.log('this.imgList', this.imgList);
      PreviewImages(index, this.imgList);
    },
    // 播放视频
    async playVideo(item) {
      const res = await showVideoList({
        url: item.url
      });
      if (res.status == 0) {
        this.showVideo = true;
        let defaultValue = res.data.default ? res.data.default : getListOne(res.data.list);
        let new_src = res.data.list[defaultValue];
        this.videoUrl = new_src;
      }
    }
  }
};