import CompProCard from "./pro_card.vue";
export default {
  props: {
    pro_info: {
      type: Object
    },
    reward_info: {
      type: Object
    },
    reward_num: {
      type: [String, Number]
    }
  },
  components: {
    CompProCard
  },
  methods: {
    dealContent(str) {
      if (str) {
        return str.replace(/↵/g, '<br/>').replace(/\n/g, '<br/>');
      } else {
        return str;
      }
    }
  }
};