export default {
  props: ["refund_time_line"],
  computed: {
    ifActive() {
      let index = 0;
      this.refund_time_line.forEach(item => {
        if (item.time) index += 1;
      });
      return index == this.refund_time_line.length;
    }
  }
};