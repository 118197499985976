import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f9a1b8f2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pro-wrap"
};
const _hoisted_2 = {
  class: "reward-info"
};
const _hoisted_3 = {
  class: "reward-info-tit"
};
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CompProCard = _resolveComponent("CompProCard");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_CompProCard, {
    pro_info: {
      pro_id: $props.pro_info.id,
      name: $props.pro_info.name,
      url: $props.pro_info.logo2
    }
  }, null, 8, ["pro_info"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, "回报档：￥" + _toDisplayString($props.reward_info.reward_money) + " (" + _toDisplayString($props.reward_info.reward_title) + ")", 1), _createElementVNode("div", null, "×" + _toDisplayString($props.reward_num), 1)]), $props.reward_info.reward_contents ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "reward-info-cont",
    innerHTML: $options.dealContent($props.reward_info.reward_contents)
  }, null, 8, _hoisted_4)) : _createCommentVNode("", true)])]);
}